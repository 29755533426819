import React, { useState } from "react";
import { Pagination } from "../Pagination";
import { FaPlusSquare, FaSearch } from "react-icons/fa";
import LeadsApi from "./LeadsApi";

export default function Leads() {
  const pageSize = 10; 
  const [currentPage, setCurrentPage] = useState(1); 
  const [searchQuery, setSearchQuery] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All Email");
  const [selectStatus, setSelectStatus] = useState("Select Emails Status");
  const closeModal = () => setshowModal(false);
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const renderPageNumbers = () => {
    // Calculate the range of page numbers to display
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(Math.ceil(totalItems / pageSize), currentPage + 2);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
          onClick={() => setCurrentPage(i)}
        >
          <span className="page-link">{i}</span>
        </li>
      );
    }

    return pageNumbers;
  };

  const updateTotalItems = (length) => {
    setTotalItems(length);
  };

  return (
    <div>
      <section className="dash-section">
        <div className="container-fluid p-0">
          <div className="row p-0 m-0">
            <div className="col-lg-12 col-md-12 col-sm-12 right-content">
              <div className="head-name">
                <h3>Leads</h3>
              </div>

              <div className="data-content">
                <div className="top-button">
                  <div className="all-drop">
                <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedCategory || "Select Email Category"}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li onClick={() => setSelectedCategory("All Email")}>
                          All Email
                      </li>
                      <li onClick={() => setSelectedCategory("Ham Emails")}>
                          Ham Emails
                      </li>
                      <li onClick={() => setSelectedCategory("Spam Emails")}>
                          Spam Emails
                      </li>
                    </ul>
                    
                  </div>
                <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectStatus || "All Mail"}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li onClick={() => setSelectStatus("Select Emails Status")}>
                    Select Emails Status
                      </li>
                      <li onClick={() => setSelectStatus("Pending")}>
                          Pending
                      </li>
                      <li onClick={() => setSelectStatus("Send")}>
                         Send
                      </li>
                     
                    </ul>

                  </div>
                  </div>
                 
                  <div className="searchBox">
                    <input
                      className="searchText"
                      type="text"
                      placeholder="Type to Search..."
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <div className="searchBtn" disabled>
                      <FaSearch />
                    </div>
                  </div>
                </div>
                <table className="table table-striped table-hover blog-table">
                  <thead className="thead-dark">
                    <tr>
                    <th scope="col" width="3%">
                      View
                      </th>
                      <th scope="col" width="10%">
                        Name
                      </th>
                      <th scope="col" width="10%">
                      Category
                      </th>
                      <th scope="col" width="15%">
                      Email
                      </th>
                      <th scope="col" width="12%">
                        Phone No.
                      </th>
                      <th scope="col" width="15%">
                       Subject
                      </th>
                      <th scope="col" width="15%">
                      Date
                      </th>
                      
                      <th scope="col" width="10%">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <LeadsApi
                    page={currentPage}
                    selectedCategory={selectedCategory}
                    selectStatus = {selectStatus}
                    pageSize={pageSize}
                    searchQuery={searchQuery}
                    totalLength={updateTotalItems}
                   
                  />
                </table>
                <Pagination
                  currentPage={currentPage}
                   setCurrentPage = {setCurrentPage}
                  totalItems={totalItems}
                  pageSize={pageSize}
                  onPrevPage={handlePrevPage}
                  onNextPage={handleNextPage}
                  renderPageNumbers={renderPageNumbers}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
