import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../apiContext";
import ViewForm from "./ViewForm";
import {FaEye } from "react-icons/fa";

export function LeadsApi({ page = 1, pageSize, searchQuery, totalLength, selectedCategory, selectStatus }) {
  const { data, error } = useContext(ApiContext);
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    try {
      if (!data.leads || data.leads.length === 0) {
        throw new Error("No data available");
      }
  
      let filteredData = data.leads;

      filteredData = filteredData.sort((a,b) =>{
        return new Date(b.dateTime) - new Date(a.dateTime);
      })
  
      if (selectedCategory === "Ham Emails") {
        filteredData = filteredData.filter(lead => lead.classification === "ham");
      } else if (selectedCategory === "Spam Emails") {
        filteredData = filteredData.filter(lead => lead.classification === "spam");
      }
      if (selectStatus === "Pending") {
        filteredData = filteredData.filter(lead => lead.mail_send_flag === "0");
      } else if (selectStatus === "Send") {
        filteredData = filteredData.filter(lead => (lead.mail_send_flag !== "0"));
      }
  
      if (searchQuery) {
        const searchLower = searchQuery.toLowerCase();
        filteredData = filteredData.filter(lead =>
            (lead.fullname && lead.fullname.toLowerCase().includes(searchLower)) ||
            (lead.Email && lead.Email.toLowerCase().includes(searchLower)) ||
            (lead.PhoneNumber && lead.PhoneNumber.toLowerCase().includes(searchLower)) ||
            (lead.dateTime && lead.dateTime.toLowerCase().includes(searchLower))
        );
    }
  
      setFilteredLeads(filteredData);
      totalLength(filteredData.length);
    } catch (error) {
      setFilteredLeads([]);
      totalLength(0);
      console.error("Error fetching or processing data:", error);
    }
  }, [data.leads, searchQuery, page, pageSize, totalLength, selectedCategory]);
  
  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedLeads = filteredLeads.slice(startIndex, endIndex);

    return (
      <>
       <tbody>
          {filteredLeads.length === 0 ? (
            <tr>
              <td className="noFound" colSpan="12">No data found</td>
            </tr>
          ) : (
            paginatedLeads.map((lead, index) => {
              const id = `item-${index}`;

              const formattedProduct = Array.isArray(lead.product)
                ? lead.product.join(", ")
                : lead.product;
                const fetchData = async () => {
                  try {
                    setModalData(lead);
                  } catch (error) {
                    console.error(error);
                  }
                };
              return (
                <tr key={id}>
                    <td
              title="View"
              onClick={() => {
                setShowModal(true);
                fetchData();
              }}
            >
              <FaEye />
            </td>
                  <td title={lead.fullname}>{lead.fullname}</td>
                  <td title={lead.classification}>{lead.classification}</td>
                  <td title={lead.Email}>{lead.Email}</td>
                  <td title={lead.PhoneNumber}>{lead.PhoneNumber}</td>
                  <td title={lead.subject}>{lead.subject}</td>
      
                  <td title={lead.dateTime}>
                    {lead && lead.dateTime ? lead.dateTime : "Not available"}
                  </td>
                  <td>
                    <span className={`status ${lead.mail_send_flag !== "0" ? "active" : "inactive"}`}>
                      {lead.mail_send_flag !== "0" ? "Send" : "Pending"}
                    </span>
                  </td>
                  {showModal && (
            <ViewForm Modalclose={closeModal} modalData={modalData} />
          )}
                </tr>
              );
            })
          )}
        </tbody>
      </>
    );
  }
}export const LeadLength = () => {
    const { data } = useContext(ApiContext);
    return data.leads.length;
  };

export default LeadsApi;
