import React from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody, Col, Row } from "reactstrap";

export default function ViewForm({ Modalclose, modalData }) {
  if (!modalData) {
    return null;
  }
  const formattedProduct = Array.isArray(modalData.product)
  ? modalData.product.join(", ")
  : modalData.product;

  const heading = modalData.mailType.charAt(0).toUpperCase() + modalData.mailType.slice(1);

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>Lead Details</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <h3 style={{color:"#526cfe"}}>{heading}</h3>
                  <h6 style={{color:"#526cfe"}}>Request Deatils :</h6>
                  <div className="content">
                    {modalData.fullname &&(
                    <div className="data-fields">
                    <label className="heading">Name :</label>
                    <p>{modalData.fullname}</p>
                    </div>
                    )}
                   {modalData.PhoneNumber &&(
                    <div className="data-fields">
                    <label className="heading">Contact Number :</label>
                    <p>{modalData.PhoneNumber}</p>
                    </div>
                    )}
                    {modalData.Email &&(
                    <div className="data-fields">
                    <label className="heading">Email :</label>
                    <p>{modalData.Email}</p>
                    </div>
                    )}
                    {modalData.classification &&(
                    <div className="data-fields">
                    <label className="heading">Category :</label>
                    <p>{modalData.classification}</p>
                    </div>
                    )}
                     {formattedProduct &&(
                    <div className="data-fields">
                    <label className="heading">Interested Product :</label>
                    <p>{formattedProduct}</p>
                    </div>
                    )}
                    {modalData.subject &&(
                    <div className="data-fields">
                    <label className="heading">Subject :</label>
                    <p>{modalData.subject}</p>
                    </div>
                    )}  
                    {modalData.needs &&(
                    <div className="data-fields">
                    <label className="heading">Needs :</label>
                    <p>{modalData.needs}</p>
                    </div>
                    )}
                   
                    {modalData.dateTime &&(
                    <div className="data-fields">
                    <label className="heading">Date :</label>
                    <p>{modalData.dateTime}</p>
                    </div>
                    )}
                    <div className="data-fields">
                    <label className="heading">Status :</label>
                    <span className={`status ${modalData.mail_send_flag !== "1" ? "inactive" : "active"}`}>
                          {modalData.mail_send_flag !== "1" ? "Pending" : "Send"}
                        </span>
                    </div>
                    
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
